import React from 'react'
import TopNav from './TopNav';

const Shop = () => {
  return (
    <div>
      <TopNav /> 
      <p>Story</p>
    </div>
  )
}

export default Shop